import axios from 'axios';

import config from '../config';
import {getItem} from "../hooks/useLocalStorage";


export default (url?: string) => {
  const customAxios = axios.create(
    {baseURL: url || `${config.apiUrl}/editor`}
  );

  customAxios.interceptors.request.use(
    (config) => {
      const userCode = getItem('user');
      const user = userCode ? JSON.parse(userCode) : null;

      if (user && user.accessToken) {
        config.headers.authorization = `Bearer ${user.accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return customAxios;
};
