import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {useForm} from 'react-hook-form';
import {Select, useToast} from '@chakra-ui/react'
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useUpdateBillingInfo} from 'api/user-api';
import {useEffect} from "react";
import useCountryList from 'hooks/useCountryList';
import { CountryItem } from 'services/country-list';


export default function ProfileSettings(editor: any) {
  const toast = useToast();
  const {countryList} = useCountryList();

  const [
    {
      loading: updateBillingInProgress,
      error: updateBillingError,
    },
    updateBillingInfo
  ] = useUpdateBillingInfo();

  useEffect(
    () => {
      if (updateBillingError) {
        toast({
          title: 'Error updating billing data, we are working on it',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [updateBillingError]
  );

  const billingSchema = z
    .object({
      billingName: z.string().nonempty('Billing name is required'),
      taxNumber: z.string().nonempty('Tax Number is required'),
      billingCountry: z.string().nonempty('Billing country is required'),
      bankName: z.string().nonempty('Bank name is required'),
      billingCity: z.string().nonempty('Billing city is required'),
      accountNumber: z.string().nonempty('Account number is required'),
      billingZip: z.string().nonempty('Billing zip is required'),
      iban: z.string().nonempty('IBAN is required'),
      billingAddress: z.string().nonempty('Billing address is required'),
      bankSwift: z.string().nonempty('SWIFT is required'),
    });

  type FormValues = z.infer<typeof billingSchema>

  const defaultValues = {
    billingName: editor?.editor?.billing?.name,
    taxNumber: editor?.editor?.billing?.taxNumber,
    billingCountry: editor?.editor?.billing?.address?.country || 'DE',
    bankName: editor?.editor?.billing?.bankName,
    billingCity: editor?.editor?.billing?.address?.city,
    accountNumber: editor?.editor?.billing?.bankAccountGiro,
    billingZip: editor?.editor?.billing?.address?.zipCode,
    iban: editor?.editor?.billing?.bankAccountIban,
    billingAddress: editor?.editor?.billing?.address?.street,
    bankSwift: editor?.editor?.billing?.bankSwift,
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: zodResolver(billingSchema),
  })

  const handleSaveClick = async (data: FormValues) => {
    await updateBillingInfo({data});
    toast({
      title: 'Billing data updated succesfully',
      status: 'success',
      duration: 3000,
    });
  };

  const watchBillingCountry = watch('billingCountry')

  return (
    <form onSubmit={handleSubmit(handleSaveClick)}>
      <Box>
        <SimpleGrid columns={2} spacingX={4} spacingY={4}>
          <FormControl id="billingName" isInvalid={!!errors.billingName}>
            <FormLabel>Billing name</FormLabel>
            <Input {...register('billingName')} variant='main'/>
            <FormErrorMessage>
              {errors.billingName && errors.billingName.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="taxNumber" isInvalid={!!errors.taxNumber}>
            <FormLabel>Tax Number</FormLabel>
            <Input {...register('taxNumber')} variant='main'/>
            <FormErrorMessage>
              {errors.taxNumber && errors.taxNumber.message?.toString()}
            </FormErrorMessage>
          </FormControl>

{          <FormControl id="billingCountry" {...register('billingCountry')} isInvalid={!!errors.billingCountry}>
            <FormLabel>Billing country</FormLabel>
            <Select
              w={'300px'}
              h='50px'
              bg='white'
              borderRadius='15px'
              {...register('billingCountry')} 
              value={watchBillingCountry} 
            >
            {countryList.map((country: CountryItem) => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
            </Select>
            <FormErrorMessage>
              {errors.billingCountry && errors.billingCountry.message?.toString()}
            </FormErrorMessage>
          </FormControl>}

          <FormControl id="bankName" isInvalid={!!errors.bankName}>
            <FormLabel>Bank</FormLabel>
            <Input {...register('bankName')} variant='main'/>
            <FormErrorMessage>
              {errors.bankName && errors.bankName.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="billingCity" isInvalid={!!errors.billingCity}>
            <FormLabel>Billing city</FormLabel>
            <Input {...register('billingCity')} variant='main'/>
            <FormErrorMessage>
              {errors.billingCity && errors.billingCity.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="accountNumber" isInvalid={!!errors.accountNumber}>
            <FormLabel>Account number</FormLabel>
            <Input {...register('accountNumber')} variant='main'/>
            <FormErrorMessage>
              {errors.accountNumber && errors.accountNumber.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="billingZip" isInvalid={!!errors.billingZip}>
            <FormLabel>Billing zip</FormLabel>
            <Input {...register('billingZip')} variant='main'/>
            <FormErrorMessage>
              {errors.billingZip && errors.billingZip.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="iban" isInvalid={!!errors.iban}>
            <FormLabel>IBAN</FormLabel>
            <Input {...register('iban')} variant='main'/>
            <FormErrorMessage>
              {errors.iban && errors.iban.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="billingAddress" isInvalid={!!errors.billingAddress}>
            <FormLabel>Billing address</FormLabel>
            <Input {...register('billingAddress')} variant='main'/>
            <FormErrorMessage>
              {errors.billingAddress && errors.billingAddress.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="bankSwift" isInvalid={!!errors.bankSwift}>
            <FormLabel>SWIFT</FormLabel>
            <Input {...register('bankSwift')} variant='main'/>
            <FormErrorMessage>
              {errors.bankSwift && errors.bankSwift.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <Button
          variant='photonDark'
          mt='20px'
          isLoading={updateBillingInProgress}
          type='submit'
        >
          Save
        </Button>
      </Box>
    </form>
  );
}
